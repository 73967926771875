import React from "react";
import { graphql } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { getImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { BgImage } from "gbimage-bridge";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import LogoCloud from "../components/Repeating/LogoCloud";
import Testimonials from "../components/Repeating/Testimonials";
import CallToAction from "../components/Repeating/CTA";
import ScrollWrapper from "../components/Scroll/ScrollWrapper";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ScrollContent from "../components/Scroll/ScrollContent";

const StyledContent = styled.div`
  ul {
    ${tw`list-disc pl-7 mb-6 flex flex-col space-y-0.5`}
    li {
      ${tw`text-sm lg:text-base tracking-wider lg:tracking-normal`}
    }
  }
  h3 {
    ${tw`font-body normal-case tracking-wider	text-base font-bold`}
  }
  p {
    ${tw`text-sm lg:text-base`}
  }
`;

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";

  const heroImages = [
    getImage(data.heroDesktop.childImageSharp.gatsbyImageData),
    {
      ...getImage(data.heroMobile.childImageSharp.gatsbyImageData),
      media: `(max-width: 767px)`,
    },
  ];

  return (
    <Layout headerStyle="overlap">
      <SearchEngineOptimization
        title="Hotel Housekeeping Services | Hospitality Staffing"
        description="Need five-star hotel housekeeping services nationwide? Our skilled cleaning staff will ensure your guests have an optimal experience. Learn more!"
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-48 md:pt-64 lg:pt-80 xl:pt-[490px] pb-14 md:pb-52 lg:pb-[415px] relative mb-8 lg:mb-0">
        <BgImage
          className="inset-0 w-full h-full bg-center"
          image={heroImages}
          style={{ position: "absolute" }}
        />
        <div className="container relative">
          <h1 className="mb-4 md:mb-4 md:max-w-[560px]">Hotel Housekeeping</h1>
          <p className="md:text-xl mb-0 md:max-w-sm">
            Immaculate, five-star cleanliness your guests will love.
          </p>
        </div>
      </section>

      <LogoCloud className="relative lg:-top-60 mb-16 md:-mb-6" />

      <section className="relative mb-20 md:mb-32">
        <div className="absolute top-0 w-full"></div>
        <div className="relative container">
          <ScrollWrapper className="grid lg:grid-cols-12 gap-y-16 lg:gap-x-24">
            <ScrollNavigation className="lg:col-start-1 lg:col-span-4">
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-1"}
                  title="About"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-2"}
                  title="Our Custom Approach"
                  stripHash
                  className="no-underline"
                />
              </li>
              <li className="text-primary-500 tracking-wide uppercase font-bold flex items-center lg:before:w-0 lg:before:bg-primary-500 lg:before:h-px lg:before:transition-all lg:before:duration-500 lg:before:ease-linear">
                <AnchorLink
                  to={url + "#anchor-3"}
                  title="Benefits"
                  stripHash
                  className="no-underline"
                />
              </li>
            </ScrollNavigation>

            <ScrollContent className="lg:col-end-13 lg:col-span-8">
              <StyledContent>
                <div id="anchor-1" className="mb-14 lg:mb-16">
                  <h2>Our Commitment to Cleanliness</h2>
                  <p>
                    Cleanliness is one of the main factors that can influence a
                    guest’s opinion of your hotel and whether they decide to
                    revisit in the future. At Hotel Cleaning Services, our
                    skilled cleaning staff is committed to ensuring your guests
                    have an optimal experience when they stay at your hotel.
                  </p>
                </div>

                <div id="anchor-2" className="mb-14 lg:mb-16">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    Our Custom Approach to Services
                  </h2>
                  <p>
                    We understand that every hotel has specific housekeeping and
                    cleaning needs, and that’s why we deliver a full scope of
                    housekeeping services. We work with you to customize your
                    requirements for:
                  </p>
                  <ul>
                    <li>Front Lobby Areas</li>
                    <li>Guest and Employee Restrooms</li>
                    <li>Back of House Service Hallways</li>
                    <li>Kitchens and Restaurants</li>
                    <li>Spas, Exercise Rooms, Other Guest Amenity Areas</li>
                    <li>Hotel and Resort Rooms</li>
                    <li>Carpets, Hard Floors</li>
                  </ul>
                </div>

                <div id="anchor-3">
                  <h2 className="text-mobile-4xl md:text-4xl">
                    Benefits of Outsourced Hotel Housekeeping
                  </h2>
                  <h3>Decades of Experience in Hospitality</h3>
                  <p>
                    Over 30 years of experience cleaning luxury hotels and
                    resorts has given our company time to perfect our
                    techniques. We guarantee you and your guests will be
                    impressed by the level of cleanliness and professionalism we
                    bring to your property. You no longer have to worry about
                    your public spaces and back of house spaces being clean,
                    sanitized, and ready for work every morning.
                  </p>

                  <h3>Highly-Trained Staff</h3>
                  <p>
                    We perform a thorough vetting process for all staff members.
                    Our team conducts background checks, reference checks, and
                    comprehensive interviews. You can trust that our staff will
                    be well-suited for your property.  
                  </p>

                  <h3>The Proper Tools for High-Quality Results</h3>
                  <p>
                    Having properly trained employees is only half the equation
                    when it comes to high-quality housekeeping. Access to the
                    right equipment is an essential component of what we offer
                    to make your hotel sparkle and shine.
                  </p>
                </div>
              </StyledContent>
            </ScrollContent>
          </ScrollWrapper>
        </div>
      </section>

      <Testimonials />
      <CallToAction
        heading={[
          "Count on the ",
          <span className="text-primary-500">Experts</span>,
        ]}
        subtext="We have high-skilled housekeeping staff who stand ready to exceed the expectations of you and your guests. Contact us today!"
      />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Hotel Cleaning.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Hotel Cleaning.jpg" }
    ) {
      publicURL
    }
    heroDesktop: file(
      relativePath: {
        eq: "services/2.2 Hotel Housekeeping/1.0 Hotel Cleaning Desktop.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
    heroMobile: file(
      relativePath: {
        eq: "services/2.2 Hotel Housekeeping/1.0 Hotel Cleaning mobile.jpg"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;
export default Page;
